<template>
  <div style="height: 1100px">
    <div class="top-select">
      <span class="con-size">分类名称</span>
      <el-input
        v-model="name"
        placeholder="请输入分类名称"
        style="width: 150px"
      ></el-input>
      <!-- <el-button
        class=""
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="handleQuery"
        >搜索</el-button
      > -->
      <img
        src="../../assets/images/query.png"
        alt=""
        @click="handleQuery"
        style="width: 49px; margin-left: 10px"
      />
    </div>
    <div class="table-content">
      <el-table :data="tableData1" style="width: 100%">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="分类编号" width="150">
        </el-table-column>
        <el-table-column prop="name" label="分类" width="160">
        </el-table-column>
        <el-table-column prop="totalCount" label="包含条目数量" width="180">
        </el-table-column>
        <el-table-column prop="remark" label="备注说明"> </el-table-column>
        <el-table-column prop="updateDate" label="修改时间" width="180">
        </el-table-column>
        <el-table-column prop="status" label="可用状态"> </el-table-column>
        <el-table-column prop="sort" label="排序号"> </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom">
      <div class="bottom-left">
        <el-button round class="top-btn" @click="addList">增加</el-button>
      </div>
      <div class="bottom-right">
        <div class="bottom-fen">
          <!-- 分页 -->
          <div class="pages">
            <!-- <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="this.pageSize"
                layout="total, prev, pager, next"
                :total="this.totalCount"
              >
                <img class="lefts" src="../../assets/images/leftpage.png" alt />
                <img
                  class="rights"
                  src="../../assets/images/rightpage.png"
                  alt
                />
              </el-pagination> -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 15, 20, 25, 30]"
              :page-size="pageSize"
              :current-page="pageCount"
              layout="total, prev, pager, next,jumper,sizes"
              :total="totalCount"
              @pagination="getTypeData"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加的弹框 -->
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="分类名称">
            <el-input
              v-model="formInline.name"
              placeholder="输入分类名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注说明">
            <el-input v-model="formInline.remark" placeholder="选填"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="formInline.sort" placeholder="选填"></el-input>
          </el-form-item>
          <el-form-item label="">
            <!-- <el-button type="success" @click="addLabel">增加</el-button> -->
            <el-checkbox
              true-label="0"
              false-label="1"
              v-model="formInline.status"
              ref="statusRef"
              class="offen"
              >启用</el-checkbox
            >
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="preservation">保 存</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      totalPage: null,
      //增加的弹框
      dialogVisible: false,
      isXian: true,
      typeOption: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "条目编号",
        },
        {
          value: "3",
          label: "分类",
        },
        {
          value: "4",
          label: "标题",
        },
      ],
      value: "",
      input: "",
      input1: "",
      name: "",
      tableAll: "",
      ByIdsAll: "",
      tableData1: [],
      //分页
      currentPage1: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1, //网络请求的页码
      //增加的表单
      formInline: {
        title: "",
        type: "",
        summary: "",
        desc: "",
        id: "",
        sort: "",
        createDate: null,
        updateDate: null,
      },
      checked: false,
      //标签
      heals: "", //tag
      inputLabel: "",
    };
  },
  created() {
    //常用知识分类
    this.selectType();
    this.getTypeData();
  },
  methods: {
    getTypeData() {
      debugger;
      apiShout
        .CommonKnowleTypeLists({
          pageCount: this.pageCount,
          pageSize: this.pageSize,
          name: this.name,
        })
        .then((res) => {
          //console.log("常用知识：", res.data);
          if (res.data.code === 200) {
            this.tableData1 = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            // this.totalPage = res.data.result.page.totalPage;
            // this.pageSize = res.data.result.page.pageSize;
            // this.pageCount = res.data.result.page.pageCount;
            // this.getResultsTable();
          }
        });
    },
    //增加
    addList() {
      this.dialogVisible = true;
      this.formInline = {};
      console.log(this.formInline.status,'status')
      this.$refs.statusRef.model = '1';
    },
    //取消弹框
    cancel(){
      this.dialogVisible = false;
      console.log(this.$refs.statusRef,'statusRef')
      this.$refs.statusRef.model = '1';
      console.log('取消')
    },
    //保存
    preservation() {
      // debugger
      console.log(this.formInline.createDate, "createDate");
      if (this.formInline.id) {
        this.formInline.createDate = [];
        this.formInline.updateDate = [];
        apiShout
          .updateCommonTypes(
            this.formInline,
            this.formInline.createDate,
            this.formInline.updateDate
          )
          .then((res) => {
            // console.log("编辑常用知识", res.data);
            if (res.data.code === 200) {
              this.$message({
                message: "编辑成功！",
                type: "success",
              });
              this.dialogVisible = false;
              // window.location.reload();
              this.getTypeData();
            }
          });
      } else {
        this.formInline.createDate = [];
        this.formInline.updateDate = [];
        apiShout
          .insertCommonType(
            this.formInline,
            this.formInline.createDate,
            this.formInline.updateDate
          )
          .then((res) => {
            // console.log("创建常用知识：", res.data);
            if (res.data.code === 200) {
              this.$message({
                message: "创建成功！",
                type: "success",
              });
              this.dialogVisible = false;
              this.getTypeData();
              // window.location.reload();
            }
          });
      }
    },
    selectType() {
      apiShout.getCommonKnows().then((res) => {
        this.typeOption = res.data.result;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getTypeData();
    },
    /**
     * 富文本编辑器
     */
    getEditor(msg) {
      console.log(msg);
      this.formInline.desc = msg;
    },
    // //增加标签事件
    // addLabel(){
    //   this.biaoqian();
    // },
    biaoqian(val) {
      // console.log('标签：',val);
      this.formInline.tag += val;
      this.inputLabel = "";
    },
    //点击按钮事件
    change() {
      this.isXian = false;
    },
    change1() {
      this.isXian = !this.isXian;
    },
    handleEdit(index, row) {
      this.formInline.id = row.id;
      this.ByIdsAll = row.id;
      apiShout.CommonKnowleTypeByIds({ id: this.ByIdsAll }).then((res) => {
        console.log(res, "查询详情");
        if (res.data.code === 200) {
          this.dialogVisible = true;
          this.formInline = res.data.result;
        }
      });
    },
    //tableAll
    handleDelete(row) {
      this.tableAll = row.id;
      this.$confirm("是否删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        apiShout.deleteKnowleTypes({ id: this.tableAll }).then((res) => {
          console.log(res, "删除成功");
          // window.location.reload();
          this.getTypeData();
        });
      });
    },
    //分页
    handleSizeChange(size) {
      this.pageSize = size;
      // this.currentPage = 1;
      console.log(this.pageSize, "padesize大小");
      // this.getPageData()
      this.getTypeData();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      // this.pageCount = page
      console.log(this.pageCount);
      this.getTypeData();
      // this.getPageData()
      // this.menulist(val);
    },
  },
  //模糊搜索
  handlChange() {
    if (this.searchs == "") {
      (this.tableData = ""),
        //获取常用知识
        apiShout
          .getCommonKnowledge({
            pageCount: this.pageCount,
            pageSize: this.pageSize,
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.tableData = res.data.result.data;
              this.totalCount = res.data.result.page.totalCount;
            }
          });
      console.log(apiShout.getCommonKnowledge(), "清空搜索框展示数据");
    }
  },
  // 模糊搜索 点击事件
  reset() {
    let val = {
      text: this.searchs, //把搜索框的值传给后端
    };
    apiShout.searchKnowles(val).then((res) => {
      // this.dataLists = res.data.result; //与查看接口一致 这步是不能少的
      this.tableData1 = res.data.result; //与查看接口一致 这步是不能少的
    });
  },

  // 查看接口 === 搜索接口
  // dim() {
  //   let val = {
  //     text: this.searchs, //把搜索框的值传给后端
  //   };
  //   apiShout.searchKnowles(val).then((res) => {
  //     // this.dataLists = res.data.result; //与查看接口一致 这步是不能少的
  //     this.tableData = res.data.result; //与查看接口一致 这步是不能少的
  //     //如果有分页,调一下分页方法
  //     // console.log(this.dataList, "模糊搜索================");
  //     // console.log(this.dataLists.length);
  //     // if (this.tableData.length > 0) {
  //     //   for (var i = 0; i < this.tableData.length; i++) {
  //     //     console.log(this.tableData[i].type);
  //     //     console.log(this.tableData[i].childs);
  //     //     for (var j = 0; j < this.tableData[i].childs.length; j++) {
  //     //       console.log(this.tableData[i].childs[j].text);
  //     //       this.tableData = this.tableData[i].childs[0].text;
  //     //     }
  //     //   }
  //     // } else {
  //     //   this.tableData = "";
  //     // }
  //   });
  // },
};
</script>

<style lang='less' scoped>
.top {
  height: 200px;
  margin: 20px 20px 20px 40px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}
.top-select {
  float: left;
}
.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
  font-size: 14px;
  font-weight: 700;
  color: #909399;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
}
.buttons {
  .el-button {
    width: 145px;
    height: 30px;
    border-radius: 25px;
    margin-left: 20px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 19px;
    padding: 0;
    float: right;
  }
}
.bottom-b {
  float: right;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  position: absolute;
  left: 100px;
  top: 10px;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
}
.offen {
  margin-left: 150px;
}
</style>