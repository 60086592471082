<template>
  <div
    class="topOne"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top" v-if="isXian">
      <el-button round class="top-btn" @click="change">分类管理</el-button>
      <div class="top-select">
        <el-form :model="SerachInput">
          <!-- <el-select
            v-model="SerachInput.value"
            placeholder="请选择"
            style="width: 90px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <!-- <el-input
            v-model="SerachInput.type"
            placeholder="请输入内容"
            style="width: 150px"
            @keyup.enter.native="handleQuery"
          ></el-input> -->
          <span class="con-size">标题</span>
          <el-input
            v-model="SerachInput.title"
            placeholder="请输入内容"
            style="width: 150px"
            @keyup.enter.native="handleQuery"
          ></el-input>
          <img
            src="../../assets/images/query.png"
            alt=""
            @click="handleQuery"
            style="width: 49px; margin-left: 10px"
          />
        </el-form>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <!-- <el-table-column type="selection" width="70px">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.checked"
                @change="selections(scope.row)"
              ></el-checkbox>
            </template>
          </el-table-column> -->
          <el-table-column prop="id" label="条目编号" width="150">
          </el-table-column>
          <el-table-column prop="type" label="分类" width="150">
          </el-table-column>
          <el-table-column prop="title" label="标题" width="130">
          </el-table-column>
          <el-table-column prop="summary" label="内容摘要"> </el-table-column>
          <el-table-column prop="updateDate" label="修改时间" width="160">
          </el-table-column>
          <el-table-column prop="tag" label="标签"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <el-button round class="top-btn" @click="addList">增加</el-button>
          <span class="bottom-shu">共计{{ totalCount }}条数据</span>
        </div>
        <div class="bottom-right">
          <div class="bottom-b">
            <!-- <el-checkbox v-model="checkAll" @change="SelectAll"
              >全选</el-checkbox
            > -->
            <el-button
              type="info"
              :disabled="disabledBtn"
              style="
                width: 100%;
                height: 30px;
                font-size: 13px;
                padding: 5px;
                margin-left: 0px;
              "
              @click="centerDialogVisible = true"
              >批量修改分类</el-button
            >
            <el-dialog :visible.sync="centerDialogVisible" width="30%" center>
              <span>
                <el-form
                  :inline="true"
                  :model="formInline"
                  class="demo-form-inline"
                >
                  <el-form-item label="选择分类">
                    <el-select
                      v-model="formInline.type"
                      placeholder="选择分类"
                      @change="cheages"
                    >
                      <el-option
                        v-for="item in typeOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item> </el-form
              ></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false"
                  >取 消</el-button
                >
                <el-button type="primary" @click="centerDialog"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- <el-button round class="top-btn1">全选</el-button> -->
            <!-- <el-button round class="top-btn1">批量修改分类</el-button> -->
          </div>
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 15, 20, 25, 30]"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next,jumper,sizes"
                :total="totalCount"
                @pagination="getData"
              >
              </el-pagination>
              <!-- <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="this.pageSize"
                layout="total, prev, pager, next"
                :total="this.totalCount"
              >
                <img class="lefts" src="../../assets/images/leftpage.png" alt />
                <img
                  class="rights"
                  src="../../assets/images/rightpage.png"
                  alt
                />
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button>数据分析</el-button>
            <el-button>数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="top" v-if="!isXian">
      <el-button round class="top-btn" @click="change1">条目管理</el-button>
      <Classification />
    </div>
    <!-- 增加的弹框 -->
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="标题">
            <el-input
              v-model="formInline.title"
              placeholder=""
              @change="textCount(formInline.title)"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择分类">
            <el-select v-model="formInline.type" placeholder="">
              <el-option
                v-for="item in typeOption"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容摘要">
            <el-input
              v-model="formInline.summary"
              placeholder="选填"
            ></el-input>
          </el-form-item>
          <el-form-item label="正文" prop="text">
            <Wangeditor
              @getEditor="getEditor"
              v-model="formInline.text"
              ref="Fueditor"
            />
          </el-form-item>
          <el-form-item label="">
            <!-- <el-input
              type="text"
              v-model="inputLabel"
              placeholder="请输入标签"
              style="width: 100px"
            ></el-input>
            <el-button type="success" @click="addTag()">增加</el-button
            >{{ error }}
            <div class="healLabel">
              <el-tag
                v-for="(item, index) in arr"
                :key="index"
                closable
                @close="removeTag(index)"
                size="small"
                style="cursor: pointer; margin-left: 3px"
              >
                {{ "#" + item.tag }}
              </el-tag> -->
            <!-- <div v-html="formInline.tag" class="healLabel"></div> -->
            <!-- </div> -->
            <!-- <el-checkbox v-model="checked" class="offen">常用条目</el-checkbox> -->
            <div class="healLabel">
              <el-tag
                :key="tag"
                v-for="tag in arr"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)"
              >
                {{ "#" + tag }}
              </el-tag>
            </div>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ 增加</el-button
            >
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="preservation">保 存</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
import Classification from "./Classification.vue";
import Wangeditor from "./Wangeditor.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      clientHeight: document.body.clientHeight,
      disabledBtn: true,
      totalPage: null,
      namearr: [],
      //增加的弹框
      dialogVisible: false,
      centerDialogVisible: false,
      isXian: true,
      arr: [],
      arrTwo: [],
      inputVisible: false,
      inputValue: "",
      error: "",
      typeOption: [],
      typeOptions: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "条目编号",
        },
        {
          value: "3",
          label: "分类",
        },
      ],
      SerachInput: {
        value: "",
        type: "",
        title: "",
      },
      value: "",
      input: "",
      input1: "",
      searchs: "",
      title: "",
      tableData: [],
      tableAll: "",
      ByIdsAll: "",
      tableData1: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          time: "2021-10-20",
          label: "#健康，#血糖，#数据检测，#常用知识，#血糖仪",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          time: "2021-10-20",
          label: "#健康，#血糖，#数据检测，#常用知识，#血糖仪",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
          time: "2021-10-20",
          label: "#健康，#血糖，#数据检测，#常用知识，#血糖仪",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
          time: "2021-10-20",
          label: "#健康，#血糖，#数据检测，#常用知识，#血糖仪",
        },
      ],
      //分页
      currentPage1: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1, //网络请求的页码
      //增加的表单
      formInline: {
        title: "",
        type: "",
        summary: "",
        desc: "",
        id: "",
        createDate: null,
        updateDate: null,
        tag: "",
        text: "",
      },
      checked: false,
      checkedLists: false,
      //标签
      heals: "", //tag
      inputLabel: "",
      checkedList: "",
      checkedType: "",
      operateName: "",
      idAll: "",
      checkAll: false,
      ids: "",
      typed: "",
      multipleSelection: [],
      minId: "",
      textContent: {},
      textCounts: null,
      numbers: 50,
      flag: true,
    };
  },
  components: {
    Wangeditor,
    Classification,
  },
  watch: {
    textContent(val) {
      console.log("2222:", val);
      this.$nextTick(() => {
        console.log("监听数据修改了", val);
        this.$refs.Fueditor.editor.txt.html(val);
      });
      // console.log(this.$refs);
    },
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  created() {
    //常用知识分类
    this.getData();
    this.selectType();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  methods: {
    textCount(val) {
      this.flag = false;
      this.textCounts = val;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
      this.minId = "";
      this.multipleSelection.forEach((item) => {
        this.minId += ` ${item.id},`;
      });
    },
    handleQuery() {
      // if (this.SerachInput.value == "2") {
      //   this.ids = this.SerachInput.type;
      // } else if (this.SerachInput.value == "3") {
      //   this.typed = this.SerachInput.type;
      // }
      this.getData();
    },
    getData() {
      apiShout
        .getCommonKnowledge({
          pageCount: this.pageCount,
          pageSize: this.pageSize,
          title: this.SerachInput.title,
        })
        .then((res) => {
          //console.log("常用知识：", res.data);
          if (res.data.code === 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            // this.totalPage = res.data.result.page.totalPage;
            // this.pageSize = res.data.result.page.pageSize;
            // this.pageCount = res.data.result.page.pageCount;
            // this.getResultsTable();
          }
        });
    },
    //增加
    addList() {
      this.dialogVisible = true;
      this.formInline = {};
      // this.inputValue = "";
      this.arr = [];
      console.log(this.$refs.Fueditor, "wangEditor");
      if (this.$refs.Fueditor != undefined) {
        this.$refs.Fueditor.editor.txt.clear();
      }
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
      this.formInline = {};
      this.$refs.Fueditor.editor.txt.clear();
      this.formInline.tag = "";
      this.arr = [];
      // this.reload();
    },
    //保存
    preservation() {
      if (this.flag == false) {
        if (this.numbers < this.textCounts.length) {
          this.$message.error("标题内容过长无法提交");
          return false;
        }
      }
      console.log(this.formInline.createDate, "createDate");
      if (this.formInline.id) {
        this.formInline.createDate = [];
        this.formInline.updateDate = [];
        for (let a = 0; a < this.typeOption.length; a++) {
          if (this.formInline.type == this.typeOption[a].name) {
            this.formInline.type = this.typeOption[a].id;
          }
        }
        for (var i = 0; i < this.arr.length; i++) {
          console.log(this.arr[i], "输出tag");
          this.namearr.push(this.arr[i]);
        }
        this.formInline.tag = this.namearr.join(",");
        apiShout
          .updateCommons(
            this.formInline,
            this.formInline.createDate,
            this.formInline.updateDate
          )
          .then((res) => {
            // console.log("编辑常用知识", res.data);
            if (res.data.code === 200) {
              this.formInline.tag = "";
              this.arr = [];
              this.dialogVisible = false;
              this.$message({
                message: "编辑成功！",
                type: "success",
              });
              // this.reload();
              // console.log('====00000:',this.$refs.Fueditor);
              this.$refs.Fueditor.editor.txt.clear();
              // window.location.reload();
            }
          });
      } else {
        this.formInline.createDate = [];
        this.formInline.updateDate = [];
        for (var j = 0; j < this.arr.length; j++) {
          console.log(this.arr[j], "输出tag");
          this.namearr.push(this.arr[j]);
        }
        this.formInline.tag = this.namearr.join(",");
        apiShout
          .getInsertKnowle(
            this.formInline,
            this.formInline.createDate,
            this.formInline.updateDate
          )
          .then((res) => {
            // console.log("创建常用知识：", res.data);
            if (res.data.code === 200) {
              this.formInline.tag = "";
              this.arr = [];
              this.dialogVisible = false;
              this.$message({
                message: "创建成功！",
                type: "success",
              });
              this.getData();
              // this.reload();
              (this.formInline = {}),
                console.log("====00000:", this.$refs.Fueditor);
              this.$refs.Fueditor.editor.txt.clear();
            }
          });
      }
    },
    selectType() {
      apiShout.getCommonKnows().then((res) => {
        this.typeOption = res.data.result;
        this.typeOptions = res.data.result;
        console.log(this.typeOptions, "123");
      });
    },
    SelectAll(val) {
      if (val == true) {
        this.disabledBtn = false;
        // this.idAll
      } else {
        this.disabledBtn = true;
      }
    },
    /**
     * 富文本编辑器
     */
    getEditor(msg) {
      console.log(msg);
      this.formInline.text = msg;
    },
    // addTag() {
    //   if (this.inputLabel == "") {
    //     this.error = "";
    //     return false;
    //   }
    //   if (this.in_array(this.inputLabel, this.arr)) {
    //     this.error = "标签已经存在！";
    //   } else {
    //     console.log(this.arr, "输出标签");
    //     this.arr.push({ tag: this.inputLabel });
    //     this.inputLabel = "";
    //     if (this.inputLabel == "") {
    //       this.error = "";
    //     }
    //   }
    // },
    // removeTags(val, index) {
    //   debugger;
    //   console.log(val, "查看内容");
    //   debugger;
    //   this.arrTwo.splice(index, 1);
    // },
    // removeTag(index) {
    //   debugger;
    //   this.arr.splice(index, 1);
    // },
    handleClose(tag) {
      this.arr.splice(this.arr.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.arr.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // in_array(search, array) {
    //   for (var i in array) {
    //     if (array[i].tag == search) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
    // //增加标签事件
    // addLabel() {
    //   // this.formInline.tag.push(this.inputLabel)
    //   debugger;
    //   this.formInline.tag += "#" + this.inputLabel;
    //   this.inputLabel = "";
    //   // this.biaoqian();
    // },
    //点击按钮事件
    change() {
      this.isXian = false;
    },
    change1() {
      this.isXian = !this.isXian;
    },
    handleEdit(index, row) {
      this.formInline.id = row.id;
      this.ByIdsAll = row.id;
      apiShout.CommonKnowleByIds({ id: this.ByIdsAll }).then((res) => {
        console.log(res, "查询详情");
        if (res.data.code === 200) {
          this.dialogVisible = true;
          this.formInline = res.data.result;
          this.textContent = res.data.result.text;
          for (let a = 0; a < this.typeOption.length; a++) {
            if (this.formInline.type == this.typeOption[a].id) {
              this.formInline.type = this.typeOption[a].name;
              console.log("操作人选中项发生变化", this.formInline.type);
            }
          }
          if (res.data.result.tag == null) {
            return false;
          } else {
            this.arr.push(res.data.result.tag.replace(/,/, "#"));
            // this.arr = res.data.result.tag.replace(/,/,"#")
          }
          // var listArr = res.data.result.tag
          console.log(res.data.result.tag, "res.data.result.tag");
          console.log(this.arr, "查看标签0");
        }
      });
    },
    //tableAll
    handleDelete(row) {
      this.tableAll = row.id;
      this.$confirm("是否删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        apiShout.deleteCommon({ id: this.tableAll }).then((res) => {
          console.log(res, "删除成功");
          this.reload();
        });
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      // this.currentPage = 1;
      console.log(this.pageSize, "padesize大小");
      // this.getPageData()
      this.getData();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      // this.pageCount = page
      console.log(this.pageCount);
      this.getData();
      // this.getPageData()
      // this.menulist(val);
    },
    centerDialog() {
      console.log(this.multipleSelection, "kkkk");
      console.log(this.minId, "uuuu");
      // let ids={
      //   ids :this.formInline.type
      // }
      // const ids = [];
      var ids = this.minId;
      var type = this.operateName;
      // const str1 = String(this.formInline.type);
      // const pad = str1.split();

      // ids.push(this.formInline.type)
      // ids.split('')
      apiShout.batchUpType({ ids, type }).then((res) => {
        console.log(res, "批量修改");
        this.centerDialogVisible = false;
        this.reload();
      });
    },
    selections(row) {
      if (row.checked == true) {
        // let arr = []
        // this.checkedList.push(row.id)
        // arr.push(row.id)
        // console.log(this.checkedList,arr,"111")
        this.disabledBtn = false;
        this.checkedType = row.type;
      } else {
        this.disabledBtn = true;
      }
    },
    cheages(val) {
      this.operateName = val;
    },
    bbmm(row) {
      console.log(row);
    },
  },
};
</script>

<style lang='less' scoped>
// .topOne{
//   height: 1200px";
// }
.top {
  height: 200px;
  margin: 20px 20px 20px 40px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}
.top-select {
  float: left;
}
.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
  font-size: 14px;
  color: #909399;
  font-weight: 700;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
}
// .buttons {
//   .el-button {
//     width: 145px;
//     height: 30px;
//     border-radius: 25px;
//     margin-left: 20px;
//     margin-top: 20px;
//     background: #d78673;
//     color: #ffffff;
//     font-size: 19px;
//     padding: 0;
//     float: right;
//   }
// }
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  position: absolute;
  left: 100px;
  top: 10px;
  color: #606266;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
  margin-left: 6px;
}
.offen {
  margin-left: 150px;
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table th.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
.el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
}
/deep/.el-button.is-round {
  border-radius: 20px;
  padding: 10px 20px;
}
/deep/.el-input__inner {
  border-radius: 0;
  height: 33px;
  line-height: 33px;
}
/deep/.el-form-item__label {
  color: #909399;
}
/deep/.el-dialog__body {
  padding: 0;
  margin-left: 3%;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>